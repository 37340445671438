
import { useEffect } from 'react';
import { Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { useThemeContext } from "../hooks/usethemeContext";
import { switchTheme } from '../assets/styles';


export default function Redirect() {
    
    // define styles by darkMode
    const { darkMode } = useThemeContext();
    const { bg, text } = switchTheme(darkMode);

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/')
        }, 3000)
    })

    return <>
        <div id="contact" className={`pt-5 mt-5 justify-items-center bg-${bg} ${text}`} >
            <Container className='text-center mx-auto' style={{height: '60vh'}}>
                <h5>Sorry That Page Does Not Exist!</h5>
                <h5>You're Being Transfered To The Homepage</h5>
            </Container>
        </div>
    </>;
}