
// define style by darkMode
export function switchTheme(darkMode) {
    const altblue = 'rgb(20, 104, 238)';
    const secondary = '#9900cc';
    const pinkish = '#b046d4';
    const white = 'whitesmoke';
    const btnLight = 'btn-light';
    const dark = pinkish;
    const light = '#d8e0e7'; // bootstrap $light
    const red = 'rgb(237, 71, 41)';
    const warning = 'rgb(211, 165, 47)';

    return {
        advantagesIconColor: { color: darkMode ? pinkish : secondary, },
        bg: darkMode ? 'dark' : 'light',
        borderTop: { borderTop: darkMode ? ` 1px solid ${light}` : ` 1px solid ${dark}` },
        buttonColors: {
            red: secondary,
            blue: pinkish,
            altblue: altblue,
            btnLight: btnLight,
        },
        color: darkMode ? 'light' : 'light',
        backgroundColor: darkMode ? 'bg-success' : 'bg-info',
        dealStyles: { color: darkMode ? pinkish : secondary },
        makePaymentStyles: { color: darkMode ? white : 'black' },
        formLabelstyle: darkMode ? 'backgroundCcolor : bg-success' : 'backgroundColor: bg-body',
        funFactIconColor: { color: darkMode ? pinkish : secondary, },
        iconStyle: { fontSize: '49px', color: darkMode ? warning : pinkish, },
        mainBannerTextColor: darkMode ? 'text-light' : 'text-secondary',
        PayPalButtonsStyle: darkMode ? 'white' : 'black',
        pricingBackgroundColor: darkMode ? 'color: bg-success' : 'color: bg-body',
        shadow: darkMode ? "shadow" : "shadow-sm",
        text: darkMode ? 'text-light' : 'text-dark',
        theme: darkMode ? 'bg-dark' : 'bg-light',
        variant: darkMode ? 'dark' : 'light',
    };
};