
import FunFacts from "./FunFacts";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ReactCarousel from "./ReactCarousel";


const AboutMe = () => {

	const style = {color: '#0e65d7'};
    const sm = '12', lg = '6';// xm = 1,

    return <>
        <div className='about-area mt-5 pb-3'>
			<div className="text-center pb-3 border-bottom-0">
				<h1 style={style}>
					The leading Tax Service Provider
				</h1>
				<h2>
					<span style={style} >for </span>your <span style={style} >personal</span>,
					<span style={style} > and </span>your <span style={style} >business </span>needs!
				</h2>
			</div>
			<FunFacts />
			<Container className="justify-items-center">
				<Row className="justify-items-center">
					<Col sm={sm} lg={lg} >
						<Row>
							<div className="pt-3">
								<h5 className="text-center" >Who I am!</h5>
								<p>
										Hello My name is Danielle Wilson, but I go by Dee.  I'm happily married and a mother of 5 children. Tragically, I lost my four year old daughter in a house fire on 9/11/11. We love to travel and visit beautiful parts of this beautiful world.  The beach always has a special place in my heart!
									</p>
									<p>
										I've been in the Industry for 10 years now and I started doing taxes at 25 years old. I'm the owner/CEO of Dee's Software and Dee's Tax Service LLC located in Crowley, LA. I manage on average over 2000 clients, but it wasn't always this way. I've worked very hard over the years to educate myself and others in the tax industry.  My hard work and dedication to client care and client satisfaction is the sole reason why my clients place their trust in me with their personal and financial information.
									</p>
									<p>
										I started filing tax returns on a cell phone using myself and my brothers as “dummy returns”. Then, I took a tax course with H&R Block and I received my PTIN with them, but then I decided that I should work for myself. I was able to learn other techniques by watching other tax preparers and previous returns. I always did love working with numbers, so it didn't take much to figure it out. Then I started filing tax returns for other family, co-workers and their family members, and the list goes on.
									</p>
									<h5 className="text-center" >My Commitment to You!</h5>
									<p>
										I truly enjoy working with you and helping your family and business with your taxes. My goal is to handle your diverse tax needs while giving each of you the personal attention you deserve. So here I am today, a Louisiana girl who worked her way to become an Authorized EFile Provider with my own Tax Firm, Tax Software, and teaching others how to do what I did!
									</p>
							</div>
							<NavLink to="/contact" className=" mt-3 btn btn-outline-primary">
								Contact us
							</NavLink>
						</Row>
					</Col>
					
					<Col className="d-flex align-items-center pt-5" sm={sm} lg={lg}>
						<Row>
							<ReactCarousel page='aboutme'/>
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	</>;
};

export default AboutMe;
