
import { useEffect } from "react";
import stripe from "../../../assets/images/stripe.svg";
import affirm from "../../../assets/images/affirm.webp";
import afterPay from "../../../assets/images/afterPay.webp";


export default function Stripe (props) {

    useEffect(() => {
    }, []);

    const Form = () => {
        return <div key={props.id} id={props.id}>
            <form action={process.env.REACT_APP_STRIPE_CHECKOUT} method="POST">
            {/* <form action={process.env.REACT_APP_STRIPE_CHECKOUT} method="POST"> */}
                <input type="hidden" name="itemPrice" value={props.price} />
                <button
                    className="btn btn-warning fst-italic text-black"
                    disabled={props.disabled}
                    type="submit"
                >   <div>
                        <img src={stripe} width='20%' alt="after-pay" />&nbsp; 
                        <img src={affirm} width='20%' alt="affirm" /> 
                        <img src={afterPay} width='40%' alt="after-pay" />
                    </div>
                </button>
            </form>
        </div>
    }
    

    return <>
        <section id='stripe'>
            <Form />
        </section>
    </>
}