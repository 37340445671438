
import MainBanner from './sub-components/MainBanner';
import AboutMe from "./sub-components/AboutMe";


const Home = () => {

  return (
    <>
      <div className='justify-items-center'>
        <section>
          <MainBanner page='home' />
        </section>
        <section>
          <AboutMe />
        </section>
      </div>
    </>
  );
};

export default Home;
