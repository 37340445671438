
import { ThemeProvider } from './hooks/usethemeContext';
import NavBar from './components/layout/Navbar';
import Home from './components/Home';
import ClientServices from './components/ClientServices';
import BusinessOpportunities from './components/BusinessOpportunities';
import Contact from './components/Contact';
import Confirmation from './components/Confirmation';
import Redirect from './components/Redirect';
import Footer from './components/layout/Footer';
import CalendlyPopUp from './components/sub-components/buttons/CalendlyPopUp';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

function App() {
  return <>
  <Router >
    <ThemeProvider>
    <NavBar/>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/clients' element={<ClientServices />} />
        <Route path='/business-opportunities' element={<BusinessOpportunities />} />
        <Route path='/contact' element={<Contact />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path='*' element={<Redirect />} />
      </Routes>
      <Footer />
    <CalendlyPopUp />
    </ThemeProvider>
  </Router>
  </>;
}

export default App;
