
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { mainBannerText } from '../sub-components/helpers/mainBannerText';
import { Container } from "react-bootstrap";
import CalendlyButton from '../sub-components/buttons/CalendlyButton';
import ReactCarousel from './ReactCarousel';


const MainBanner = (props) => {

	// define style by darkMode
	const { darkMode } = useThemeContext();
	const { mainBannerTextColor, backgroundColor } = switchTheme(darkMode);
	let textBanner = mainBannerText.home;

	if(props.page === 'clients') textBanner = mainBannerText.taxClient;
    else if(props.page === 'software') textBanner = mainBannerText.taxPartner;

	return <>
		<div className='pt-5'>
			<Container fluid id="main-banner" className={`main-banner d-flex align-items-center flex-column ${backgroundColor} ${mainBannerTextColor} pb-5 pt-5v`}>
				<Container className="pb-3">
					{ textBanner.heading1 }
				</Container>

				<Container>
					<ReactCarousel />
				</Container>
			</Container>
			
			{	
				props.page === 'software' ?
				<Container className='d-flex align-items-center flex-column pb-5'>
					<Container className="mt-4 pb-3">
						{textBanner.heading2}
					</Container>

					<CalendlyButton />
				</Container>
				:
				<></>
			}
		</div>
	</>;	
};

export default MainBanner;
