
import { useEffect } from "react";
import CalendlyInline from "./sub-components/buttons/CalendlyInline";


const Contact = () => {
	useEffect(() => {
    })

    return <>
		<div className='justify-items-center'>
			<section>
				<CalendlyInline />
			</section>
		</div>
	</>;
}

export default Contact;
