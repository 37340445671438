
import MainBanner from "./sub-components/MainBanner";
import Advantages from "./sub-components/Advantages";


const ClientServices = () => {

    return <>
        <div>
            <section>
                <MainBanner page='clients' />
            </section>
            <section>
                <Advantages page='clients' />
            </section>
        </div>
    </>;
};
 
export default ClientServices;
