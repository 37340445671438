
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Container, Col, Row } from "react-bootstrap";


const FunFacts = () => {
	// define styles by darkMode
    const { darkMode } = useThemeContext();
    const { color, backgroundColor, funFactIconColor } = switchTheme(darkMode);
    const sm = 6, lg = 3, xs = 12;


    return (
        <div className={`fun-fact-area ${backgroundColor} `}>
			<Container className='d-block justify-items-center'>
				<Row className="mx-3 align-self-center">
					<Col xs={xs} sm={sm} lg={lg}>
						<div style={{color}} className="single-fact">
							<i className="bi bi-hourglass-split" style={funFactIconColor}></i>
							<h2 style={funFactIconColor}>
                                11 <span>+</span>
							</h2>
	
							<p>Years Tax In Business</p>
						</div>
					</Col>

					<Col xs={xs} sm={sm} lg={lg}>
						<div style={{color}} className="single-fact">
							<i className="bi bi-people" style={funFactIconColor}></i>
	
							<h2 style={funFactIconColor}>
                                40 <span>+</span>
							</h2>
	
							<p>Business Partners</p>
						</div>
					</Col>

					<Col xs={xs} sm={sm} lg={lg}>
						<div style={{color}} className="single-fact">
							<i className="bi bi-person-plus" style={funFactIconColor}></i>
	
							<h2 style={funFactIconColor}>
                                2000 <span>+</span>
							</h2>
	
							<p>Clients served</p>
						</div>
					</Col>

					<Col xs={xs} sm={sm} lg={lg}>
						<div style={{color}} className="single-fact">
							<i className="bi bi-cash-coin" style={funFactIconColor}></i>
	
							<h2 style={funFactIconColor}>
                                95 <span>%</span>
							</h2>

							<p>funding rate</p>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
    )
}

export default FunFacts;
