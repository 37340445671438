
import { useNavigate, useLocation } from 'react-router-dom';
import { Container } from "react-bootstrap";

const Confirmation = () => {
    const navigate = useNavigate();
    let location = null
    location = useLocation();

    const Success = () => {
        return <div>
            <p>Thank you for your purchase!</p>
            <p>Check your email to create your software</p>
            <ul>
                <li>Check spam folder if it's not in the inbox</li>
            </ul>
            <p>Upload your documents using our secure form</p>
            <ul>
                <li>Click the link to Jotform</li>
                <li>Your information and docs are encrypted</li>
            </ul>
            <p>Click on the Service Agreement link</p>
            <ul>
                <li>Complete the agreement & submit it</li>
                <li>You'll receive the signed agreement via email</li>
            </ul>
            <div className='mt-5' style={{display: 'flex', justifyContent: 'center'}}>
                <button 
                    className="btn btn-success"
                    type='button'
                    onClick={() => {navigate('/')}}
                >
                    Go Back To The Homepage
                </button>
            </div>
        </div>
    }

    const Cancelled = () => {
        return <div style={{ display: 'block', width: '400px', paddingTop: '200px', paddingBottom: '200px'}} className="text-center mx-auto" onLoad={setTimeout(() => {navigate('/business-opportunities')}, 3000)} >
            <h5>Your transaction was canceled</h5>
            <h5>Please check your info and try again.</h5>
            <h5>Going back to Business Opportunities</h5>
        </div>
    }

    const NoPurchases = () => {
        return <div style={{ display: 'block', width: '400px', paddingTop: '200px', paddingBottom: '200px'}} className="text-center mx-auto" onLoad={setTimeout(() => {navigate('/business-opportunities')}, 3000)} >
            <h5>You don't have any current purchases</h5>
            <h5>Goint to Business Opportunities</h5>
        </div>
    }

    return <>
        <div id="contact" style={{ marginTop: '100px', minHeight: "65vh" }}>
            <Container style={{display: 'flex', justifyContent: 'center', maxWidth: '500px'}}>
                {
                    location.state !== null ?
                        location.state.status === 'success' ?
                            <Success />
                            :
                            <Cancelled />
                    :
                    <NoPurchases />
                }
            </Container>
        </div>
    </>;
}
 
export default Confirmation;