
const h3Style = {
    color:'text-light',
    textAlign: 'center'
};


export const advantagesText = {
    taxClient: {
        heading1: 
            <div className="text-center ">
                <h3 style={h3Style}>
                    Our services are tailored to meet all your needs, protect you, and your privacy
                </h3>
                <h3>
                    Our business clients also experience the <span>best services in the industry</span>
                </h3>
                
            </div>,
        box1: 
            <>
                <h3>Audit Protection</h3>
                <p>Audit Maintenance provides you a 3-year professional audit coverage. An experienced AMP Enrolled Agent or CPA is assigned to every case and assistance is available year-round. The company offers a 100% money-back satisfaction guarantee.
                </p>
            </>,
        box2: 
            <>
                <h3>Security</h3>
                <p>We partnered with SecurelyID (powered by Experian™) to offer you a complete identity theft monitoring protection and restoration product. It includes advanced monitoring to detect identity theft and restoration expertise if you are a victim.</p>
            </>,
        box3:
            <>
                <h3>TaxesToGo!</h3>
                <p>Securely upload documents and ID's or view your tax return(s) with TaxesToGo mobile app. Enjoy peace of mind knowing that your information is encrypted & protected. You can also sign and submit your return via the app and chat with your preparer.</p>
            </>,
        box4:
            <>
                <h3>Free Consultation</h3>
                <p>We offer a free assesment of your Tax needs. We will sit along side you, listen to your situation and concerns, and provide you with initial thoughts and potential solutions. Fianally, we will give you our recommendation and estimate</p>
            </>,
        box5: 
            <>
                <h3>Business Software</h3>
                <p>We service your personal and business needs. Our Tax Software manages any type of personal taxes as well as business tax filings.  The Business Desktop software can handle any type of business structure.
                </p>
            </>,
        box6: 
            <>
                <h3>Bank Products</h3>
                <p>Bank products guarantee that you receive refunds securely and directly to your account, this means that there are no out-of-pocket cost for tax preparation services! Tax preparation fees are automatically sent to your tax preparer.</p>
            </>,
        box7:
            <>
                <h3>Tax Advances</h3>
                <p>With our fast approval process, you could walk out of your office with needed funds for bills, etc, the same day you file your return. Our NO-COST Taxpayer Advance Loan options get high marks for their cost efficiency and convenience.</p>
            </>,
        box8:
            <>
                <h3>Appointments</h3>
                <p>Your choice! Make an appointment to come and meet us at our office or you can schedule a virtual appointment/consultation. You'll recieve the same level of professionalism for your unique situation no matter how we meet.</p>
            </>,
    },
    taxPartner: {
        heading1: 
            <div className="text-center">
                <h3 style={h3Style}>
                    All The Tools & Support You Need For Your Personal & Business Growth
                </h3>
                <h3>
                    And To Help You Make Training Easy
                </h3>
				
            </div>,
        box1: 
            <>
                <h3>Encrypted Data</h3>
                <p>Work remotely and enjoy peace of mind knowing that your information is protected with high level encryption.
                </p>
            </>,
        box2: 
            <>
                <h3>Learn & Grow</h3>
                <p>Our proven training and marketing program will help you close more clients and allow you to focus on growing.</p>
            </>,
        box3:
            <>
                <h3>Taxes To Go!</h3>
                <p>Reach more clients and prepare tax returns in less time. Chat with your clients in real time from within the app.</p>
            </>,
        box4:
            <>
                <h3>Mentorship</h3>
                <p>Schedule individual advanced Marketing and Hiring Strategies (eg: email, socila-media, job-websites).  </p>
            </>,
    }
}
