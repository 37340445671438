
import Carousel from 'react-bootstrap/Carousel';
import pic1 from '../../assets/images/pic1.webp';
import pic2 from '../../assets/images/pic2.webp';
import pic3 from '../../assets/images/pic3.webp';
import pic4 from '../../assets/images/pic4.webp';
import pic5 from '../../assets/images/pic5.webp';
import pic6 from '../../assets/images/pic6.webp';
import pic7 from '../../assets/images/pic7.webp';
import pic8 from '../../assets/images/pic8.webp';
import pic9 from '../../assets/images/pic9.webp';


export default function ReactCarousel(props) {
  const intervalTime = 5000, corners = 3;
  const styles = {
    margin: props.page !== 'aboutme' ? 'auto' : 'auto',
    width: props.page !== 'aboutme' ? '70%' : '80%',
  };


  return <>
    <Carousel fade >
      <Carousel.Item interval={intervalTime}>
        <img
          className={`rounded-${corners} d-block`}
          style={styles}
          src={ props.page !== 'aboutme' ? pic7 : pic2 }
          alt="First slide"
        />
        {/* <Carousel.Caption>
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item interval={intervalTime}>
        <img
          className={`rounded-${corners} d-block`}
          style={styles}
          src={ props.page !== 'aboutme' ? pic4 : pic9 }
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={intervalTime}>
          <img
          className={`rounded-${corners} d-block`}
          style={styles}
          src={ props.page !== 'aboutme' ? pic8 : pic5 }
          alt="Third slide"
          />
      </Carousel.Item>
    </Carousel>
  </>;
}